export default [
  {
    path: '/dashboards',
    name: 'dashboard-hitler',
    component: () => import('@/views/joker168s/dashboard/dashboard.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/games',
    name: 'games',
    component: () => import('@/views/joker168s/game/games.vue'),
    meta: {
      // layout: 'full',
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/gamesjoker',
    name: 'gamesjoker',
    component: () => import('@/views/joker168s/game/gamesJoker.vue'),
    meta: {
      // layout: 'full',
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/play/:id',
    name: 'play',
    component: () => import('@/views/joker168s/game/play.vue'),
    meta: {
      layout: 'full',
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/invite-friend',
    name: 'invite-friend',
    component: () => import('@/views/joker168s/invite_friend/invite_friend.vue'),
    meta: {
      // layout: 'full',
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/Deposit',
    name: 'deposit',
    component: () => import('@/views/joker168s/deposit/deposit.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/deposit-next',
    name: 'deposit-next',
    component: () => import('@/views/joker168s/deposit/bank.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/deposit-laos/:currency',
    name: 'deposit-laos',
    component: () => import('@/views/joker168s/deposit/banklaos.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/deposit-truewallet',
    name: 'truewallet',
    component: () => import('@/views/joker168s/deposit/truewallet.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/Withdraw',
    name: 'withdraw',
    component: () => import('@/views/joker168s/withdraw/withdraw.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/promotions',
    name: 'promotions',
    component: () => import('@/views/joker168s/promotions/promotions.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  // {
  //   path: '/sms-deposit',
  //   name: 'sms-deposit',
  //   component: () => import('@/views/joker168s/sms/smsDoposit.vue'),
  //   meta: {
  //     resource: 'Admin',
  //     action: 'read',
  //   },
  // },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/joker168s/history/history.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  // {
  //   path: '/fixed-deposit',
  //   name: 'fixed-deposit',
  //   component: () => import('@/views/joker168s/fixed_deposit/fixed_deposit.vue'),
  //   meta: {
  //     resource: 'Admin',
  //     action: 'read',
  //   },
  // },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/joker168s/profile/profile.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/fixed-deposit',
    name: 'fixed-deposit',
    component: () => import('@/views/joker168s/fixed_dep/view.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/register-ufa',
    name: 'register-ufa',
    component: () => import('@/views/joker168s/registerJK/view.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/joker168s/login/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forget-password',
    name: 'forgot',
    component: () => import('@/views/joker168s/forgot-password/forgot.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
